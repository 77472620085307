// SpotifyAPI.js
import axios from "axios";

export async function getCurrentlyPlayingTrack() {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/api/currently-playing",
    { withCredentials: true }
  );
  if (!response.data.currentSong) return { currentSong: null };
  return { currentSong: response.data.currentSong };
}
