import "./App.css";
import { useCallback, useEffect, useState } from "react";
import { getCurrentlyPlayingTrack } from "./SpotifyAPI";

function App() {
  const [loggedIn, setLoginStatus] = useState("");
  const [track, setCurrentTrack] = useState(null);

  useEffect(() => {}, [track]);

  useEffect(() => {
    const status = window.location.hash.substr(1) === "success";

    if (status) {
      window.localStorage.setItem("loggedIn", true);
      setLoginStatus(true);
    }
    window.history.replaceState(null, null, " ");
  }, []);

  const pollCurrentlyPlayingTrack = useCallback(() => {
    if (!loggedIn) {
      console.log("not logged in");
      return;
    }
    const fetchTrack = async () => {
      const { currentSong } = await getCurrentlyPlayingTrack();
      setCurrentTrack(currentSong);
    };

    fetchTrack();
    const timerId = setTimeout(pollCurrentlyPlayingTrack, 250);

    return () => clearTimeout(timerId); // This is the cleanup function
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      console.log(loggedIn);
      const cleanup = pollCurrentlyPlayingTrack();
      return () => cleanup();
    }
  }, [pollCurrentlyPlayingTrack, loggedIn]);

  const renderCurrentTrack = () => {
    if (!track) return null;
    return (
      <img
        className="album-art"
        width={"50%"}
        src={track.album.images[0].url}
        alt=""
        style={{ width: "230px", height: "230px" }}
      ></img>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        {loggedIn ? (
          <div className="record">{renderCurrentTrack()}</div> // Render the current track directly
        ) : (
          <div>
            <h2>Please login</h2>
            <a href={process.env.REACT_APP_API_URL + "/login"}>Login</a>
          </div>
        )}
        <div className="track-info">
          <div className="track-name">{track?.name}</div>
          <div className="artist-name">
            {track?.artists.map((artist) => artist.name).join(", ")}
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
